<template>
<div class="bg">
    <div class="ceE">
        <div class="header">
            <div class="title">企业税务授权</div>
            <div class="tips"> · 不查征信</div>
        </div>
        <van-form @submit="onSubmit">
            <div class="form">
                <!-- 名称 -->
                <van-field v-model="companyName" name="companyName" autocomplete='off' @input="nameInputHandle" placeholder="请输入营业执照上的企业全称"
                    :rules="[ { required: true } ]" clearable />
                <!-- 下拉数据 -->
                <transition name="fade">
                    <ul class="fadeul" v-show="showList">
                        <li class="fadeli" v-for="(item, index) in results" :key="index" v-html="item.name"
                            @click="getText(item.name, item.taxAccount)"></li>
                    </ul>
                </transition>
                <van-field v-model="taxAccount" name="taxAccount" placeholder="请输入企业纳税人识别号" clearable
                    :rules="[ { required: true } ]" />
                <!-- 河南税局登录 -->
                <div class="henan" v-if="provinceCode == '410000'">
                    <van-field v-model="columns[henanParam.codeType]" is-link readonly name="codeType"
                        placeholder="选择类型" @click="showPicker = true" />
                    <van-popup v-model="showPicker" position="bottom">
                        <van-picker show-toolbar :columns="columns" @confirm="onConfirm" @cancel="showPicker = false" />
                    </van-popup>
                    <van-field v-model="henanParam.taxPassword" name="taxPassword" type="password"
                        placeholder="请输入电子税局登陆密码" :rules="[ { required: true } ]" clearable />
                </div>
                <!-- 山东税局登录 -->
                <div class="shandong" v-if="provinceCode == '370000'">
                    <van-field v-model="shandongParam.loginTelephone" name="loginTelephone" type="number"
                        placeholder="请输入手机号/身份证/操作员代码" clearable :rules="[ { required: true } ]" />
                    <van-field v-model="shandongParam.taxPassword" name="taxPassword" :rules="[ { required: true } ]"
                        type="password" placeholder="请输入电子税务局登录密码" clearable />
                    <van-field v-model="shandongParam.smsCode" name="smsCode" center :rules="[ { required: true } ]"
                        clearable placeholder="请输入验证码">
                        <template #extra>
                            <img :src="imgCode" alt="" class="smsCode" @click="getCodeImg" />
                        </template>
                    </van-field>

                </div>

            </div>
            <div class="doubt">电子税务局登陆密码 <van-icon name="question-o" /></div>
            <van-radio-group v-model="radio" style="margin-top:38px">
                <van-radio name="1" class="radio">
                    <div class="showXieYi">
                        阅读同意<a @click="showXieYe = true">《授权协议》《服务协议》《隐私政策》</a>
                    </div>
                </van-radio>
            </van-radio-group>
            <!-- 弹窗 -->
            <van-action-sheet v-model="showXieYe" title="授权协议">
                <div class="content">
                    <div class="dialogTitle_end">尊敬的客户：</div>
                    <p class="lei">
                        为了维护贵公司的合法权益，请在签署本企业信用信息采集授权书（以下简称“本授权书”）前，仔细阅读本授权书全部内容，以知悉贵公司在数据服务中的权利、义务。如对本授权书有任何疑问，需要进一步解读或解释，可与我们的客服联系,在线时间为工作日9：00到18:00。
                    </p>
                    <h3>北京微企利乐信息技术有限公司：</h3>
                    <p class="lei">
                        本公司（或称“授权人”）承诺并确认，截至本授权书生效之日，本公司是依据中国法律注册成立并有效存续的、住所位于中国境内（包括港澳台）的中国企业法人、事业单位及其他经济组织，具有独立承担法律责任的完全民事行为能力。
                    </p>
                    <h6>
                        一、授权内容
                    </h6>
                    <p class="lei">
                        本公司同意并不可撤销地授权：被授权人在本授权书约定的采集信息来源、信息采集范围内采集本公司过去36个月的相关信息；授权被授权人在本授权书约定的采集信息来源、信息采集范围内采集信贷建议匹配所需的相关信息；授权被授权人对采集抽取的以上本公司信息资料进行保存、整理、分析、比对、演算、归纳及（或）加工等各项操作，并将加工整理的信息资料生成报告；本公司对自身提供的所有信息真实性、完整性、准确性、合法性承担全部责任。
                        采集信息来源，指本公司授权人同意授权被授权人查询、采集并获取本公司在国家税务总局各省市、直辖市、自治区电子税务系统中提供、产生并存储在国税系统数据库的本公司信息；为了提高匹配方案的精准度，授权人同意授权被授权人通过安装软件插件后通过该软件插件采集并获取授权人储存在插件所在的电脑端上开票信息。此外，为了进一步提高方案匹配的精准度，本公司同意向被授权人提供中国人民银行金融信用信息基础数据库查询及获取的本公司或企业法人的信用报告。
                        信息采集范围，包括但不限于本公司如下信息：
                        <ul>
                            <li>
                                1、企业基本信息，是指公司的名称、注册资本、董监高信息、住所地址、企业联系电话、法定代表人基本信息（包括其姓名、手机号码、身份证号码）等；
                            </li>
                            <li>
                                2、税务信息，是指公司过去36个月内纳税情况及数据；
                            </li>
                            <li>
                                3、开票信息，是指公司过去36个月内的开票数据；
                            </li>
                            <li>
                                4、交易信息，是指公司与上下游企业的业务往来，交易数据等。财务信息，是指公司的内外账，经审计或未经审计的财务报表（包括利润表、资产负债表、损益表等）等；
                            </li>
                            <li>
                                5、进销存信息，是指客户名称及清单，客户的其它详细资料，销售目标、销售统计表等；
                            </li>
                            <li>
                                6、信贷信息，是指公司过往的信贷记录情况。税务信息，是指公司在国税、地税的缴税记录等；
                            </li>
                            <li>
                                7、诉讼信息，是指法院公布的公司作为原告或被告的案件诉讼信息；
                            </li>
                            <li>
                                8、奖惩信息，是指公司在国家政府部门是否受到奖惩的情况等。
                            </li>
                        </ul>
                    </p>

                    <h6>
                        二、授权使用范围
                    </h6>
                    <p class="lei">
                        本公司理解并同意，就信贷匹配服务而言，当第三方向被授权人查询本公司的相关信息时，该第三方应当获得本公司的事先书面同意，并在约定范围使用。
                    </p>
                    <h6>
                        三、授权期限
                    </h6>
                    <p class="lei">
                        信贷方案查询匹配服务为按次提供，授权人如需再次使用此服务则需再次签订本授权协议。
                    </p>
                    <h6>
                        四、授权书的形式
                    </h6>
                    <p class="lei">
                        授权人同意本授权书如以数据电文形式订立，授权人一旦通过在线点击签署或以其他方式确认同意本授权书，本授权书即生效。如以纸质文件“面签”形式签署，则自授权人签章之日生效。
                    </p>
                    <h6>
                        五、数据使用
                    </h6>
                    <p class="lei">
                        授权人同意被授权人有权对采集抽取的以上本公司信息资料进行保存、整理、分析、比对、演算、归纳及（或）加工等各项操作，并将加工整理的信息资料生成报告。
                    </p>

                </div>
            </van-action-sheet>
            <van-button class="dengl" block type="info" :loading="formIng" native-type="submit">登录</van-button>
        </van-form>
        <div class="safe">
            <i class="iconfont icon-yingyongbeifen" /> 系统全程严格保证企业数据安全
        </div>
    </div>
</div>
</template>
<script>
import url from "@/service/url-config";
export default {
  data() {
    return {
      radio: "",
      showPicker: false,
      columns: ["法人", "财务负责人", "办税员", "购票员"],
      name: "",
      provinceCode: "410000", //省code值
      showXieYe: false,
      companyName: "", //公司名称
      taxAccount: "", //公司税号
      timer: null,
      submitTurn: null,
      showList: false,
      results: [],
      henanParam: {
        taxPassword: "",
        codeType: 0,
        employeeId: localStorage.getItem("wql_user_id"),
        origin: 72,
      },
      shandongParam: {
        loginTelephone: "",
        taxPassword: "",
        smsCode: "",
        cookie: "",
        employeeId: localStorage.getItem("wql_user_id"),
        origin: 72,
      },
      imgCode: "",
      formIng: false,
    };
  },
  methods: {
    //选择法人等
    onConfirm(value, index) {
      this.showPicker = false;
      this.henanParam.codeType = index;
    },
    // 公司名称输入事件
    nameInputHandle(value) {
      if (this.timer) clearTimeout(this.timer);
      if (value && value.trim().length > 2) {
        this.timer = setTimeout(() => {
          this.$http.get(url.matchCompany + value.trim()).then((res) => {
            if (res.data && res.data.length > 0) {
              this.changeColor(res.data, value);
            } else if (res.code == 200 && res.data.length == 0) {
              this.getProvinceCode(value.trim());
            }
          });
        }, 10);
      }
    },
    // 修改返回的结果的颜色
    changeColor(data, keyword) {
      data.map((item, index) => {
        if (keyword && keyword.trim().length > 0) {
          // 匹配关键字正则
          let replaceReg = new RegExp(keyword, "g");
          // 高亮替换v-html值
          let replaceString =
            '<span style="color:#0084FF">' + keyword + "</span>";
          data[index].name = item.name.replace(replaceReg, replaceString);
        }
      });
      this.results = data;
      this.showList = true;
    },
    // 得到转化后的文本赋值给 keyWords
    getText(name, taxAccount) {
      this.showList = false;
      this.companyName = this.repalceHtmlToText(name);
      this.taxAccount = taxAccount;
      this.getProvinceCode(this.companyName);
    },
    //通过完整的公司名称查询省份
    getProvinceCode(companyName) {
      if (companyName && companyName.trim().length > 0) {
        this.$http.get(url.companyBasicTypeInfo + companyName).then((res) => {
          this.provinceCode = res.data ? res.data : "410000";
          if (this.provinceCode == 370000) this.getCodeImg();
        });
      }
    },
    // 把带有html 的文本变成text文本
    repalceHtmlToText(str) {
      str = str.replace(/<\/?.+?>/g, "");
      str = str.replace(/&nbsp;/g, "");
      return str;
    },
    //
    getCodeImg() {
      this.$http
        .post(url.getLoginCode2, {
          provinceCode: "370000",
        })
        .then((res) => {
          this.$nextTick(() => {
            const { cookie, code } = res.data;
            this.shandongParam.cookie = cookie;
            this.imgCode = `data:image/jpg;base64,${code}`;
          });
        });
    },
    onSubmit(value) {
      if (this.radio == "") {
        this.$dialog.alert({
          message: "请先勾选同意",
        });
        return;
      }
      if (this.submitTurn) clearTimeout(this.submitTurn);
      let data = {};
      this.formIng = true;
      if (this.provinceCode == 370000) {
        const { cookie, origin } = this.shandongParam;
        data = {
          ...value,
          origin,
          cookie,
        };
        this.submitTurn = setTimeout(() => {
          this.$http.post(url.shuiwu, data).then((res) => {
            this.formIng = false;
            if (res.code !== 200) {
              this.$dialog.alert({
                message: res.msg,
              });
              this.getCodeImg();
            } else {
              const { companyId, loanPredictionId } = res.data;
              this.$router.push({
                path: "match",
                query: {
                  companyId,
                  loanPredictionId,
                  taxAccount: this.taxAccount,
                },
              });
            }
          });
        }, 50);
      } else if (this.provinceCode == "410000") {
        const {
          codeType,
          // employeeId,
          origin,
        } = this.henanParam;
        data = {
          ...value,
          codeType,
          // employeeId,
          origin,
        };
        this.submitTurn = setTimeout(() => {
          this.$http.post(url.getLoginCode, data).then((res) => {
            this.formIng = false;
            if (res.code == 200) {
              const { name, telephone } = res.data;
              this.$router.push({
                name: "code",
                query: {
                  name,
                  telephone,
                  ...data,
                },
              });
            }
          });
        }, 50);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.bg {
  background: url("../../img/match/bg.png") no-repeat;
  background-size: 100%;
  padding-top: 280px;
  padding-bottom: 100px;
}
.ceE {
  box-sizing: border-box;
  font-size: 12px;
  // padding: 0 35px;
  width: 350px;
  height: 474px;
  background: #ffffff;
  border-radius: 10px;
  margin: 0 auto;

  .header {
    display: flex;
    align-items: baseline;
    padding: 20px;

    .title {
      font-size: 21px;
      font-weight: bold;
      color: #101010;
    }

    .tips {
      color: #113fc4;
      font-size: 16px;
      // margin-left: 30px;
    }
  }

  .form {
    .van-cell {
      width: 310px;
      margin-bottom: 25px;
      border-bottom: 1px solid #eeeeee;
      margin: 0 auto;
      padding: 20px 0 10px 0;
    }

    .van-cell::after {
      border-bottom: 0;
    }

    .shandong {
      .smsCode {
        height: 22px;
        width: 100px;
      }
    }
  }

  .showXieYi {
    color: #999999;
    font-size: 12px;
  }

  .dengl {
    width: 310px;
    height: 45px;
    background: #113fc4;
    border-radius: 23px;
    font-size: 18px;
    margin-top: 16px;
  }

  .safe {
    margin-top: 35px;
    text-align: center;
    color: #0b72ff;
  }

  .content {
    padding: 20px 20px;

    .lei {
      text-indent: 2em;
    }
  }

  // 下拉
  .fadeul {
    box-shadow: 0px -8px 34px 0px rgba(0, 0, 0, 0.05);
    overflow: auto;
    z-index: 999;
    position: absolute;
    width: 80%;

    max-height: 248px;
    margin: auto;
    // margin-top: -22px;
    color: #333;
    font-size: 12px;
    background-color: #fff;

    .fadeli {
      list-style: none;
      // text-align: center;
      text-indent: 15px;
      line-height: 30px;
      border-top: 1px solid #f4f4f4;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.6s;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }
  .doubt {
    font-size: 12px;
    color: #999999;
    text-align: right;
    margin-top: 10px;
  }
}
</style>